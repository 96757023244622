import request from '../components/Common.vue'

export default {
    // 登录
    login(data){
        return request.httpPost('/api/user/admin/login',data)
    },

    // 获取产品列表
    get_product_list (data) {
        return request.httpPost('/api/product/admin/find/list', data)
    },

    // 获取单个产品详细信息
    get_product_id (data) {
        return request.httpPost('/api/product/admin/find/id', data)
    },

    // 删除产品
    delete_product (data) {
        return request.httpPost('/api/product/admin/delete', data)
    },

    // 添加产品
    add_product (data) {
        return request.httpPost('/api/product/admin/add', data)
    },

    // 编辑产品
    update_product (data) {
        return request.httpPost('/api/product/admin/update', data)
    },

    // 获取产品分类列表
    get_product_type_list (data) {
        return request.httpPost('/api/product/admin/type/find/list', data)
    },

    // 获取单个产品分类的信息
    get_product_type_id (data) {
        return request.httpPost('/api/product/admin/type/find/id', data)
    },

    // 删除产品分类
    delete_product_list (data) {
        return request.httpPost('/api/product/admin/type/delete', data)
    },

    // 添加产品
    add_product_type (data) {
        return request.httpPost('/api/product/admin/type/add', data)
    },

    // 编辑产品
    update_product_type (data) {
        return request.httpPost('/api/product/admin/type/update', data)
    },

    // 获取banner列表
    get_banner_list (data) {
        return request.httpPost('/api/banner/admin/find/list', data)
    },

    // 获取单个banner详情
    get_banner_id (data) {
        return request.httpPost('/api/banner/admin/find/id', data)
    },

    // 删除banner
    delete_banner (data) {
        return request.httpPost('/api/banner/admin/delete', data)
    },

    // 添加banner
    add_banner (data) {
        return request.httpPost('/api/banner/admin/add', data)
    },

    // 编辑banner
    update_banner (data) {
        return request.httpPost('/api/banner/admin/update', data)
    },

    // 获取展示位列表
    get_banner_class_list (data) {
        return request.httpPost('/api/banner/admin/class/find/list', data)
    },

    // 获取单个展示位详情
    get_banner_class_id (data) {
        return request.httpPost('/api/banner/admin/class/find/id', data)
    },

    // 删除展示位
    delete_banner_class (data) {
        return request.httpPost('/api/banner/admin/class/delete', data)
    },

    // 添加展示位
    add_banner_class (data) {
        return request.httpPost('/api/banner/admin/class/add', data)
    },

    // 编辑展示位
    update_banner_class (data) {
        return request.httpPost('/api/banner/admin/class/update', data)
    },
}
